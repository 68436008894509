import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDateTime } from '../../utils/parsing';
import { OfferMessageType } from '../../types/OfferMessageType';

type PropsType = {
    isCurrentTenant: boolean;
    offerTenantId?: number;
    userName: string;
    createdAt: string;
    message: string;
    isEvent: boolean;
    readBy: OfferMessageType['readBy'];
}

const EVENT_COLOR = '#EEE';
export const COLOR_PURPLE = '#522653';
const OWN_COLOR = COLOR_PURPLE;
const OTHER_COLOR = '#EEE';
const COLOR_DARK_GREY = '#555';

export default function OfferChatMessage({
    isCurrentTenant,
    offerTenantId,
    userName,
    createdAt,
    message,
    isEvent,
    readBy
}: PropsType) {
    return (
        <>
            <Box
                sx={{
                    maxWidth: '90%',
                    ...(isCurrentTenant ? { marginLeft: 'auto' } : {}),
                    borderRadius: '5px',
                    // eslint-disable-next-line no-nested-ternary
                    backgroundColor: isEvent ? EVENT_COLOR : (
                        isCurrentTenant ? OWN_COLOR : OTHER_COLOR
                    ),
                    // eslint-disable-next-line no-nested-ternary
                    color: isEvent ? COLOR_DARK_GREY : (
                        isCurrentTenant ? 'white' : undefined
                    ),

                    marginBottom: 1,
                    padding: '5px 12px 3px 12px'
                }}
                display="table"
            >
                {message.split('\n').map((part, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography display="block" key={i}>
                        {part}
                    </Typography>
                ))}
                <Typography variant="caption" display="block">
                    {`${userName}, ${formatDateTime(createdAt)}`}
                </Typography>
            </Box>
            {readBy?.map((rb) => (
                <Typography
                    variant="caption"
                    display="block"
                    textAlign={rb.tenantId === offerTenantId ? 'left' : 'right'}
                    key={rb.userId}
                >
                    {`${rb.userName}, ${formatDateTime(rb.lastRead)}`}
                </Typography>
            ))}
        </>
    );
}
/*

*/
